import { BehaviorSubject } from 'rxjs';

import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ChurchApi } from '@ministrary/shared/apis/church.api';
import { UsersChurchApi } from '@ministrary/shared/apis/users-church.api';
import { eChurchStatus } from '@ministrary/shared/enums/church-status.enum';
import { ePlans } from '@ministrary/shared/enums/plans.enum';
import { eProduct } from '@ministrary/shared/enums/product.enum';
import { eSubscriptionStatus } from '@ministrary/shared/enums/subscription-status.enum';
import { iChurch } from '@ministrary/shared/interfaces/church.interface';
import { UsersChurch } from '@ministrary/shared/interfaces/users-church.interface';

@Injectable({
  providedIn: 'root'
})
export class ChurchService {
  private churchApi = inject(ChurchApi);
  private usersChurchApi = inject(UsersChurchApi);
  private router = inject(Router);

  churches$ = new BehaviorSubject<iChurch[]>([]);
  selectedChurch = signal<iChurch | undefined>(undefined);

  hasActiveSubscription: boolean;

  get churchId() {
    return this.selectedChurch()?.id;
  }

  async load(shouldSelect = true) {
    const isSuperAdmin = this.usersChurchApi.isSuperAdmin;
    const churches = isSuperAdmin ? await this.loadAllChurches() : await this.loadChurchesByUser();
    this.churches$.next(churches);

    if (!churches.length) this.router.navigate(['/link-church']);
    if (shouldSelect) this.select();

    this.loadHasActiveSubscription();
  }

  select(church_id?: string, acceptEmpty = false) {
    const churches = this.churches$.value;
    if (!churches.length) return;

    if (church_id) {
      const selectedChurch = churches.find(church => church.id === church_id);
      if (selectedChurch || acceptEmpty) return this.selectedChurch.set(selectedChurch);
    }

    return this.selectedChurch.set(churches[0]);
  }

  reset() {
    this.churches$.next([]);
    this.selectedChurch.set(undefined);
  }

  getActiveSubscriptions() {
    const church = this.selectedChurch();
    return church?.subscriptions?.filter(subscription => subscription.status === eSubscriptionStatus.ACTIVE) || [];
  }

  hasProduct(products?: eProduct[]) {
    if (!products) return true;

    const church = this.selectedChurch();
    if (!church) return false;

    const productPlanMap = {
      [eProduct.SCHEDULE]: [ePlans.MONTHLY_SCHEDULE, ePlans.ANNUAL_SCHEDULE],
      [eProduct.KIDS]: [ePlans.MONTHLY_KIDS, ePlans.ANNUAL_KIDS],
      [eProduct.CALENDAR]: [ePlans.MONTHLY_CALENDAR, ePlans.ANNUAL_CALENDAR]
    };

    const plans = products.map(p => productPlanMap[p]).flat();
    return this.hasPlan(plans);
  }

  hasPlan(plans?: ePlans[]) {
    if (!plans) return true;

    const church = this.selectedChurch();
    if (!church) return false;

    return church.subscriptions && church.subscriptions.some(subscription => subscription.subscription_items?.some(item => plans.includes(item.prices?.lookup_key as ePlans)));
  }

  async activateChurch() {
    await this.churchApi.update({ status: eChurchStatus.ACTIVE }).match({ id: this.churchId });
    await this.load();
  }

  private loadHasActiveSubscription() {
    const subscriptions = this.getActiveSubscriptions();
    this.hasActiveSubscription = !!subscriptions.length;
  }

  private async loadAllChurches() {
    const { data } = await this.churchApi.select('*, subscriptions(*, prices (*, products(*)))');
    return data as unknown as iChurch[];
  }

  private async loadChurchesByUser() {
    if (!this.usersChurchApi.userId) return [];

    const { data } = await this.usersChurchApi.select('church(*, subscriptions(*, subscription_items(*, prices (*, products(*)))))').eq('user_id', this.usersChurchApi.userId);
    if (!data) return [];

    return (data as unknown as UsersChurch[]).map(userChurch => userChurch.church) as iChurch[];
  }
}
